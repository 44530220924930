<template>
  <div class="relative w-full">
    <button
        @click="() => (showList = !showList)"
        class="relative w-full rounded-md border border-green py-2 pl-3 pr-3 text-left sm:text-sm block bg-white"
        :class="[this.$props.fill ? 'bg-green' : '']"
    >
      <span
          class="block truncate"
          :class="[
          this.$props.fill
            ? 'text-white text-center'
            : selected
            ? 'text-green'
            : 'text-gray-400',
        ]"
      >
        {{ selected ? selected.name : placeholder }}
      </span>
      <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          :class="[this.$props.fill ? 'text-white' : 'text-green']"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            class="w-6 h-6"
            :class="[showList ? 'transform rotate-180' : '']"
        >
          <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </span>
    </button>
    <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
      <div
          v-if="showList"
          class="absolute max-h-80 w-full overflow-hidden rounded-md bg-white text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-green"
          style="bottom: 40px; background: #fcfcfc"
      >
        <ul>
          <li
              v-for="(mode, key) in modes"
              :key="mode.name"
              :value="mode.id"
              as="template"
              :class="[
              mode.id === selected?.id
                ? 'bg-green bg-opacity-75 text-white'
                : 'text-gray-900 hover:bg-gray-300 hover:bg-opacity-10',
              'relative cursor-default select-none py-1 pl-2 pr-1 flex justify-between items-center',
              key > 0 ? 'border-t border-gray-400' : '',
            ]"
              class="cursor-pointer text-left relative"
              @click="handleChangeSubcription(mode)"
          >
            <div v-if="mode.id === 1" class="flex gap-2">
              <span class="font-medium block"><template>{{ mode.name }}</template>{{ mode.name }}</span>
              <span><img :src="om" alt="Gift" class="text-white w-8"/></span>
              <span><img :src="momo" alt="Gift" class="text-white w-8"/></span>
            </div>
            <div v-else class="flex gap-2" >
              <span class="font-medium block"><template>{{ mode.name }}</template>{{ mode.name }}</span>
              <span><img :src="visa" alt="Gift" class="text-white w-20"/></span>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import BestBadgeComponent from "@/components/BestBadgeComponent.vue";
import GiftComponent from "@/components/Icons/GiftComponent.vue";
import om from "../assets/om.png";
import momo from "../assets/momo.jpeg";
import visa from "../assets/visa.png"

export default {
  name: "ListBoxPaymentComponent",
  props: ["modes", "value", "placeholder", "fill"],
  emits: ["update:value"],
  components: {GiftComponent, BestBadgeComponent},
  data() {
    return {
      om,
      momo,
      visa,
      selected: null,
      showList: false,
      placeholder: this.$props.placeholder ?? "Moyen de paiement",
    };
  },
  methods: {
    handleChangeSubcription(mode) {
      this.selected = mode;
      this.showList = false;
    },

  },
  mounted() {
    this.selected = this.value;
  },
  updated() {
    this.$emit("update:value", this.selected);
  },
};
</script>
