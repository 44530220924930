<template>
  <div class="flex justify-center w-screen">
    <div class="w-full max-w-lg px-1 mb-1 rounded shadow-lg md:w-6/12 sm:w-8/12 bg-opacity-90"
         style="background-color: #ffffee">
      <section class="relative z-20 px-4 py-2 sm:py-10 sm:px-10 dark:text-white">
        <div class="px-3 sm:px-6">
          <WhatsappButtonComponent/>
        </div>
        <div class="" style="border-bottom: 2px solid #d6a686;" v-if="responsePayment ||
          newsPaperIsUnavailable ||
          !campaignNewsPaperId ||
          paymentFailedStatus.includes(paymentStatus)
          ">
          <img class="w-40 mx-auto my-5" :src="logo" alt="logo" loading="eager"/>
        </div>
        <div v-if="!newsPaperIsUnavailable && campaignNewsPaperId !== null">
          <div v-if="!responsePayment">
            <div class="py-2 border-t border-b border-green" v-if="title">
              <h1 class="flex-1 text-center md:text-lg xs:text-sm">
                {{ title }}
              </h1>
            </div>
            <PaymentFailedComponent
                message="Cher client, l'achat de votre journal n'a pas abouti. Veuillez vérifier votre solde ou choisssez un autre forfai"
                v-if="paymentFailedStatus.includes(paymentStatus)"/>
            <div class="px-5 py-4" @contextmenu="(e) => {
              e.preventDefault();
              return false;
            }
              " v-else v-if="!isVisaPayment">
              <img loading="eager" :src="newsPaperfirstPage" alt="A la une du Journal"
                   class="mx-auto w-72 md:w-96 shadow-dark"/>
            </div>
            <LoaderComponent v-if="isLoading"/>
            <fieldset class="w-full border-t border-green" v-else v-if="!isVisaPayment">
              <legend class="px-2 mx-auto text-base">
                {{ paymentMessage }}
              </legend>
              <p class="p-4 my-4 text-sm text-white bg-red-500 rounded" v-if="this.error">
                {{ this.error }}
              </p>
              <div v-if="!this.linkNewPaper" class="mt-4">
                <div class="relative grid gap-2 px-2 mt-2 mb-4 text-center sm:px-8">
                  <ListBoxComponent :subscriptions="this.subscriptions" v-model:value="subscriptionSelected"
                                    :value="subscriptionSelected"/>
                  <ListBoxPaymentComponent :modes="this.modes" v-model:value="modeSelected"
                                           :value="modeSelected"/>
                  <InputNumberComponent :value="phoneNumber" v-model:value="phoneNumber" v-model:error="error"
                                        v-if="isMobilePayment"
                  />
                  <ButtonPaymentComponent @click="pay()"/>
                </div>
              </div>
              <LoaderComponent v-else/>
            </fieldset>
          </div>
          <div v-else class="mt-10 mb-10">
            <div class="flex items-center h-64">
              <p class="w-full p-4 text-center text-<null>white rounded-md" :class="paimentInitSuccess && !this.error ? 'bg-green ' : 'bg-red-500'
                ">
                {{ paymentResponseMessage }}
              </p>
            </div>
            <PaymentInitSuccessComponent v-if="paimentInitSuccess"/>
          </div>
        </div>
        <div v-else>
          <div class="py-10 text-center rounded-sm" v-if="newsPaperIsUnavailable">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512" class="mx-auto mb-4">
              <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
            Pas de jounal disponible aujourd'hui !
          </div>
          <div class="py-10 text-center bg-white rounded-sm" v-else>
            <LoaderComponent/>
          </div>
        </div>
        <div class="mt-10 mb-10" v-if="isVisaPayment">
          <div class="mt-4 relative grid gap-2 px-2 mb-4 sm:px-8" style="background-color: #f2ecec">
            <div class="border-b border-t py-6">
              <h1 class="md:text-lg xs:text-sm flex-1 mb-10 text-center">
                <div class="flex justify-center items-center">
                  <span><img :src="visa" alt="visa" class="text-white" style="width: 200px"/></span>
                </div>
                <div style="background-color: #288017" class=" block h-8 text-lg text-white m-1 mb-5 gap-2">
                  <p> Abonnement {{ subscriptionSelected?.name }}</p>
                </div>
              </h1>
              <div class="gap-4 flex">
                <h3 class="mr-2">Montant à payer</h3>
                <span
                    class="border-b border-t py-3 bg-white rounded-md font-bold w-1/4 text-center ">{{
                    subscriptionSelected?.price
                  }}</span>
              </div>
              <div class="gap-2">
                <label for="phoneNumberVisa">Votre numéro de téléphone</label>
                <input
                    id="phoneNumberVisa"
                    type="text"
                    class="block w-4/5 mb-5 text-sm h-10 m-1 text-center font-normal text-gray-700 border-b border-solid border-gray-300 rounded focus:border-green focus:outline-none"
                    v-model="phoneNumberVisa"
                />
              </div>
              <div class="gap-2">
                <label for="emailVisa">Votre addresse e-mail</label>
                <input
                    id="emailVisa"
                    type="email"
                    placeholder="ex: cameroontribune@mail.com"
                    class="block w-4/5 mb-5 text-sm h-10 m-1 text-center font-normal text-gray-700 border-b border-solid border-gray-300 rounded focus:border-green focus:outline-none"
                    v-model="emailVisa"
                />
              </div>
            </div>
          </div>
          <div class="relative grid gap-2 px-10 mt-2 text-center sm:px-8">
            <ButtonPaymentComponent @click="payVisa" v-if="buttonVisa"/>
          </div>
        </div>
        <LoaderComponent v-if="!buttonVisa"/>
        <p class="text-center">
          <a href="https://nexah.net" class="text-black underline text-opacity-80 dark:text-white"
             style="font-size: 11px">Fourni par NEXAH</a>
        </p>
      </section>
    </div>
    <ConfettiComponent v-if="promotion"/>
    <ModalComponent v-model:modalIsOpen="modalIsOpen" :img="promotion?.img"/>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import WhatsappButtonComponent from "../components/Icons/WhatsappButtonComponent.vue";
import ListBoxComponent from "@/components/ListBoxComponent";
import ListBoxPaymentComponent from "@/components/ListBoxPaymentComponent.vue";
import PayIconComponent from "@/components/Icons/PayIconComponent";
import PaymentInitSuccessComponent from "@/components/PaymentInitSuccessComponent";
import PaymentVisaComponent from "@/components/PaymentVisaComponent.vue";
import PaymentFailedComponent from "@/components/PaymentFailedComponent";
import ButtonPaymentComponent from "@/components/ButtonPaymentComponent";
import InputNumberComponent from "@/components/InputNumberComponent";
import ModalComponent from "@/components/ModalComponent";
import ConfettiComponent from "@/components/ConfettiComponent";
import axios from "axios";
import logoImg from "../assets/logo.png";
import omImg from "../assets/om.png";
import momoImg from "../assets/momo.jpeg";
import visa from "../assets/visa.png";

export default {
  name: "Home",
  inject: ["apiUrl"],
  data() {
    return {
      isLoading: false,
      newsPaperfirstPage: null,
      logo: logoImg,
      om: omImg,
      momo: momoImg,
      title: "",
      paymentMessage: "Procéder au paiement",
      paymentResponseMessage: "",
      responsePayment: false,
      mode: "Web",
      paimentInitSuccess: false,
      paimentVisaInitSuccess: false,
      linkNewPaper: "",
      interval: null,
      phoneNumber: null,
      campaignNewsPaperId: null,
      error: null,
      operator: null,
      newsPaperIsUnavailable: false,
      subscriptions: null,
      subscriptionSelected: null,
      paymentStatus: null,
      paymentFailedStatus: ["EXPIRED", "FAILED", "CANCELLED"],
      modalIsOpen: false,
      promotion: null,
      ogImage: 'https://nxh-pic.s3.us-east-2.amazonaws.com/app/sopecam/p_1_1712833680DSIdu12042024.webp',
      modes: [
        {id: 1, name: "paiement Mobile"},
        {id: 2, name: "paiement VISA"}
      ],
      modeSelected: "",
      isVisaPayment: false,
      isMobilePayment: false,
      buttonVisa: true,
      showInputPaymentComponent: false,
      phoneNumberVisa: '',
      emailVisa: '',
      visa
    };
  },
  watch: {
    modeSelected(newValue) {
      const paymentMethodId = newValue?.id;
      if (paymentMethodId === 1) {
        this.isMobilePayment = true;
        this.isVisaPayment = false
      }
      if (paymentMethodId === 2) {
        this.isMobilePayment = false;
        this.isVisaPayment = true
      }
    },
  },
  mounted() {
    if (this.$route.params.code) {
      if (this.$route.params.code.toLowerCase() === "wa" || this.$route.params.code.toLowerCase() === "fb") {
        this.mode = this.$route.params.code.toLowerCase();
        axios
            .get(`${this.apiUrl}/newspaper-today/1`)
            .then((response) => {
              const oldThis = this;
              const image = new Image();
              image.src = response.data.data.page;
              image.addEventListener("load", (e) => {
                oldThis.displayPaymentView(response);
              });
              image.addEventListener("error", (e) => {
                oldThis.displayPaymentView(response);
              });
            })
            .catch((error) => {
              console.log(error);
              this.newsPaperIsUnavailable = true;
              /*window.location.assign("/#/weekend");*/
            });
      } else {
        this.mode = "sms"
        axios
            .post(`${this.apiUrl}/newspaper-fetch`, {
              code: this.$route.params.code,
            })
            .then((response) => {
              const oldThis = this;
              const image = new Image();
              image.src = response.data.data.page;
              image.addEventListener("load", (e) => {
                oldThis.displayPaymentCodeView(response);
              });
              image.addEventListener("error", (e) => {
                oldThis.displayPaymentCodeView(response);
              });
            })
            .catch((error) => {
              this.newsPaperIsUnavailable = true;
            });
      }
    } else {
      axios
          .get(`${this.apiUrl}/newspaper-today/1`)
          .then((response) => {
            const oldThis = this;
            const image = new Image();
            image.src = response.data.data.page;
            image.addEventListener("load", (e) => {
              oldThis.displayPaymentView(response);
            });
            image.addEventListener("error", (e) => {
              oldThis.displayPaymentView(response);
            });
          })
          .catch((error) => {
            console.log(error);
            this.newsPaperIsUnavailable = true;
            /*window.location.assign("/#/weekend");*/
          });
    }
  },
  methods: {
    async payVisa() {
      if (this.checkNumberVIsa() && this.subscriptionSelected) {
        this.buttonVisa = false
        this.isLoading = false;
        this.paymentStatus = null;
        this.title = "";
        await axios
            .post(`${this.apiUrl}/subscription-save`, {
              code: btoa(`${this.phoneNumberVisa}.${this.campaignNewsPaperId}`),
              subscriptionId: this.subscriptionSelected.id,
              canal: this.$route.params.mode ? this.$route.params.mode : this.mode,
              editionId: 1,
              first_name: this.emailVisa,
              number: this.phoneNumberVisa,
              last_name: this.emailVisa,
              email: this.emailVisa,
              payment_mode: 'VISA MASTERCARD'
            })
            .then((response) => {
              console.log(response)
              const htmlContent = response.data;
              const newTab = window.open('', '_blank');
              newTab.document.write(htmlContent);
              newTab.document.close();
              this.buttonVisa = true
            })
            .catch((error) => {
            });
        this.isLoading = false;
      } else {
        if (!this.subscriptionSelected) {
          this.error = "Sélectionnez votre forfait";
        }
      }
    },
    async pay() {
      if (this.checkNumber() && this.subscriptionSelected) {
        this.isLoading = true;
        this.paymentStatus = null;
        this.title =
            "";
        await axios
            .post(`${this.apiUrl}/subscription-save`, {
              code: btoa(`${this.phoneNumber}.${this.campaignNewsPaperId}`),
              subscriptionId: this.subscriptionSelected.id,
              canal: this.$route.params.mode ? this.$route.params.mode : this.mode,
              editionId: 1,
              payment_mode: 'MOBILE'
            })
            .then((response) => {
              this.paymentResponseMessage =
                  "Paiement initié avec succès. Veuillez patienter, vous allez recevoir une notification pour valider votre paiement";
              if (response.data.link) {
                this.linkNewPaper = response.data.link;
                this.title = response.data.message;
                this.paymentMessage = "Votre journal est en cours d'ouverture...";
                this.readNewsPaper();
              } else {
                this.operator = response.data.operator;
                this.checkPayment(response.data.payment_ref);
                this.paimentInitSuccess = true;
                this.responsePayment = true;
              }
            })
            .catch((error) => {
              this.title =
                  "Vous êtes sur le point d'effectuer le paiement de votre journal Cameroon Tribune";
              this.error =
                  error.data?.message ??
                  "Le paiement de votre journal rencontre un problème, bien vouloir réesayez plus tard!";
            });
        this.isLoading = false;
      } else {
        if (!this.subscriptionSelected) {
          this.error = "Sélectionnez votre forfait";
        }
      }
    },
    checkNumber() {
      if (this.phoneNumber) {
        const regex = new RegExp(/^(237)?6((8|7|9)\d{7}|5([0-4]|[5-9])\d{6})$/);
        if (regex.test(this.phoneNumber)) {
          this.error = false;
          return true;
        }
        this.error =
            "Le numéro saisi ne correspond pas à un numéro MTN ou Orange";
      } else {
        this.error = "Vous devez renseigner votre numéro de téléphone";
      }
      return false;
    },
    checkNumberVIsa() {
      if (this.phoneNumberVisa) {
        const regex = new RegExp(/^(237)?6((8|7|9)\d{7}|5([0-4]|[5-9])\d{6})$/);
        if (regex.test(this.phoneNumberVisa)) {
          this.error = false;
          return true;
        }
        this.error =
            "Le numéro saisi ne correspond pas à un numéro MTN ou Orange";
      } else {
        this.error = "Vous devez renseigner votre numéro de téléphone";
      }
      return false;
    },
    checkPayment(payment_ref) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        axios
            .post(`${this.apiUrl}/pay-status/` + payment_ref, {
              code: btoa(`${this.phoneNumber}.${this.campaignNewsPaperId}`),
            })
            .then((response) => {
              this.paymentStatus = response.data.status;
              if (response.data.status === "SUCCESS") {
                this.responsePayment = false;
                this.linkNewPaper = response.data.link;
                this.paymentMessage = "Votre journal est en cours d'ouverture...";
                clearInterval(this.interval);
                this.readNewsPaper();
              } else if (
                  this.paymentFailedStatus.includes(response.data.status)
              ) {
                this.responsePayment = false;
                clearInterval(this.interval);
                this.title = "";
                const index = this.subscriptions.findIndex(
                    (subscription) =>
                        subscription.id === this.subscriptionSelected.id
                );
                if (index < this.subscriptions.length - 1) {
                  this.subscriptionSelected = this.subscriptions[index + 1];
                }
                this.paymentMessage = "Choisir un autre forfait";
              }
            });
      }, 5000);
    },
    readNewsPaper() {
      window.location.assign(this.linkNewPaper);
    },
    displayPaymentView(response) {
      this.newsPaperfirstPage = response.data.data.page;
      this.campaignNewsPaperId = response.data.data.id;
      this.subscriptions = response.data.data.subscriptions;
      /* this.subscriptionSelected =
        response.data.data.subscriptions[
          response.data.data.subscriptions.length - 1
        ]; */

      this.subscriptionSelected = response.data.data.subscriptions.find(
          (subscription) => subscription.is_default === 1
      );
      this.promotion = response.data.data.promotion;
      this.modalIsOpen = null !== response.data.data.promotion;
      /*document
        .querySelector('meta[name="description"]')
        ?.setAttribute("content", response.data.data.title);
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute("content", response.data.data.title);
      document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute("content", response.data.data.title);*/
    },
    displayPaymentCodeView(response) {
      if (response.data.data.link) {
        this.title = response.data.message;
        this.paymentMessage = "Votre journal est en cours d'ouverture...";
        window.location.assign(response.data.data.link);
      } else {
        this.newsPaperfirstPage = response.data.data.page;
        this.campaignNewsPaperId = response.data.data.id;
        this.subscriptions = response.data.data.subscriptions;
        this.subscriptionSelected = response.data.data.subscriptions.find(
            (subscription) => subscription.is_default === 1
        );
        this.phoneNumber = atob(this.$route.params.code).split(".")[0];
        this.promotion = response.data.data.promotion;
        this.modalIsOpen = null !== response.data.data.promotion;
      }
      /* document
        .querySelector('meta[name="description"]')
        ?.setAttribute("content", response.data.data.title);
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute("content", response.data.data.title);
      document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute("content", response.data.data.title); */
    },
  },
  components: {
    PaymentVisaComponent,
    ListBoxPaymentComponent,
    LoaderComponent,
    ListBoxComponent,
    PayIconComponent,
    PaymentInitSuccessComponent,
    ButtonPaymentComponent,
    PaymentFailedComponent,
    InputNumberComponent,
    ModalComponent,
    ConfettiComponent,
    WhatsappButtonComponent
  },
};
</script>

<style scoped>
.shadow-dark {
  box-shadow: -1px 1px 5px 2px #8c8d8d;
}
</style>
