<template>
  <div class="mt-4 relative grid gap-2 px-2 mb-4 sm:px-8" style="background-color: #f2ecec">
    <div class="border-b border-t py-6">
      <h1 class="md:text-lg xs:text-sm flex-1 mb-4 text-center">
        VISA
        <div style="background-color: #288017" class=" block h-8 text-lg text-white m-1 mb-5 gap-2">
          <p>Abonnement semaine -5jours</p>
        </div>
      </h1>
      <div class="gap-4 flex">
        <h3 class="mr-2">Montant à payer</h3>
        <span class="border-b border-t py-3 bg-white rounded-md font-bold w-1/4 text-center ">899 XAF</span>
      </div>
      <div class="gap-2">
        <label for="phoneNumber">Votre numéro de téléphone</label>
        <input
            id="phoneNumber"
            type="text"
            class="block w-2/3 mb-5 text-sm h-10 m-1"
        />
      </div>
      <div class="gap-2">
        <label for="phoneNumber">Votre afresse e-mail</label>
        <input
            id="phoneNumber"
            type="email"
            placeholder="ex: cameroontribune@mail.com"
            class="block w-2/3 mb-5 text-sm h-10 m-1"
        />
      </div>
    </div>
  </div>
  <div class="relative grid gap-2 px-10 mt-2 text-center sm:px-8">
    <ButtonPaymentComponent @click="pay()"/>
  </div>
</template>

<script>
import om from "../assets/om.png";
import momo from "../assets/momo.jpeg";
import ButtonPaymentComponent from "@/components/ButtonPaymentComponent.vue";
import axios from "axios";

export default {
  name: "PaymentVisaComponent",
  components: {ButtonPaymentComponent},
  data() {
    return {
      om,
      momo,
    };
  },
  methods: {
    async pay() {
      if (this.checkNumber()) {
        this.isLoading = true;
        this.paymentStatus = null;
        this.title =
            "";
        await axios
            .post(`${this.apiUrl}/subscription-save`, {
              code: btoa(`${this.phoneNumber}.${this.campaignNewsPaperId}`),
              subscriptionId: this.subscriptionSelected.id,
              canal: this.$route.params.mode ? this.$route.params.mode : this.mode,
              editionId: 1
            })
            .then((response) => {
              this.paymentResponseMessage =
                  "Paiement initié avec succès. Veuillez patienter, vous allez recevoir une notification pour valider votre paiement";
              if (response.data.link) {
                this.linkNewPaper = response.data.link;
                this.title = response.data.message;
                this.paymentMessage = "Votre journal est en cours d'ouverture...";
                this.readNewsPaper();
              } else {
                this.operator = response.data.operator;
                this.checkPayment(response.data.payment_ref);
                this.paimentInitSuccess = true;
                this.responsePayment = true;
              }
            })
            .catch((error) => {
              this.title =
                  "Vous êtes sur le point d'effectuer le paiement de votre journal Cameroon Tribune";
              this.error =
                  error.data?.message ??
                  "Le paiement de votre journal rencontre un problème, bien vouloir réesayez plus tard!";
            });
        this.isLoading = false;

      }
    },
  }
};
</script>
<style>
@media (max-width: 465px) {
  .text-small {
    font-size: 0.675rem;
  }
}
</style>
