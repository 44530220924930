<template>
  <router-view />
  <RouteRedirection/>
</template>
<script>
import logo from "@/assets/logo.png";
import omImg from "@/assets/om.png";
import momoImg from "@/assets/momo.jpeg";
import RouteRedirection from "@/components/RouteRedirection.vue";

export default {
  components: {
    RouteRedirection
  }
}
const images = [logo, omImg, momoImg];
images.map((image) => {
  const img = new Image();
  img.src = image;
  img.addEventListener("load", () => {
  });
});
</script>