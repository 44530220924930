import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import Home from "@/views/Home";
import Otp from "@/views/Otp";
import RenewPackage from "@/views/RenewPackage";
import WeekendSubscription from "@/views/WeekendSubscription";
import paymentVisaComponent from "@/components/PaymentVisaComponent.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Page du jounal",
            description: "Votre Cameroon Tribune à portée de main",
        },
    },
    {
        path: "/c/:mode",
        name: "Mode",
        component: Home,
        meta: {
            title: "Page du journal",
            description: "Votre Cameroon Tribune à portée de main",
        },
    },
    {
        path: "/:code",
        name: "Code",
        component: Home,
        meta: {
            title: "Page avec code du journal",
            description: "Votre Cameroon Tribune à portée de main",
        },
    },
    {
        path: "/otp/:id",
        name: "otp",
        component: Otp,
        meta: {
            title: "Page OTP",
            description: "Page du code reçu par SMS",
        },
    },
    {
        path: "/renew/:code",
        name: "RenewPackage",
        component: RenewPackage,
        meta: {
            title: "Renouvellement d'abonnement",
            description: "Page pour le renouvellement d'un abonnement",
        },
    },
    {
        path: "/weekend/:phone?",
        name: "WeekendSubscription",
        component: WeekendSubscription,
        meta: {
            title: "Actualité de la semaine",
            description:
                "Découvrez les faits marquants de l’actualité au courant de cette semaine",
        },
    },
    {
        path: "/visa",
        name: "PaymentVisaComponent",
        component: paymentVisaComponent,

    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    const title = to.meta?.title + " | Cameroon tribune" ?? "Cameroon tribune";
    if (to.fullPath.includes('/#/')) {
        const newPath = to.fullPath.replace("/#/", "/");
        next(newPath)
    } else {
        next()
    }
    document.title = title;
    document
        .querySelector('meta[name="description"]')
        ?.setAttribute("content", to.meta.description ?? "Cameroon tribune");
    document
        .querySelector('meta[property="og:title"]')
        ?.setAttribute("content", title);
    document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute("content", to.meta.description ?? "Cameroon tribune");
    document
        .querySelector('meta[name="twitter:title"]')
        ?.setAttribute("content", title);
    document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute("content", to.meta.description ?? "Cameroon tribune");
});

export default router;