<script >
export default {
  created() {

   console.log('Component mounted.')
  }
}
</script>

<template>
<div></div>
</template>

<style scoped>

</style>